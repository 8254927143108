import { useEffect, useState } from 'react';
import type { Undefinable } from '~/utilities/type-guards';
import { isNotNil, isNotNull } from '~/utilities/type-guards';
import type { GetTargetFn, TargetType } from '~/onboarding/types';
import { noOpFn } from '~/utilities/util';
import { useObserveElementRect } from './useObserveElementRect/useObserveElementRect';

export const useTargetObserver = (
  getTarget: GetTargetFn,
  isExternalTarget: boolean,
): {
  target: TargetType;
  rect: Undefinable<DOMRect>;
} => {
  const [target, setTarget] = useState(getTarget());
  const rect = useObserveElementRect(target);

  useEffect(() => {
    if (!isExternalTarget) {
      const targetEl = getTarget();
      if (isNotNull(targetEl)) {
        setTarget(targetEl);
      }
      return noOpFn;
    }

    const observer = new MutationObserver(() => {
      const targetEl = getTarget();
      if (document.contains(targetEl)) {
        setTarget(targetEl);
        return;
      }

      if (isNotNil(target)) {
        setTarget(null);
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => observer.disconnect();
  }, [getTarget, isExternalTarget, target]);

  return { target, rect };
};
