import styled from 'styled-components';
import { Link, Ellipsis, Text, WistiaLogoNew } from '@wistia/vhs';
import { AccountLogo } from './AccountLogo';

const AccountName = styled(Text)`
  display: flex;
  max-width: 100%;

  &:hover {
    color: ${({ theme }) => theme.color.grey700};
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 3;
  height: 100%;
  justify-content: ${(props) => props.justifyContent};

  /* keeps logo visible */
  margin-right: ${(props) => (props.justifyContent === 'left' ? '3vw' : '0')};
  min-width: 32px;
  overflow: auto;
  position: relative;
`;

const SiteName = styled(Link)`
  align-items: center;
  align-self: stretch;
  color: ${({ theme }) => theme.color.grey900};
  cursor: ${({ href }) => (href ? 'pointer' : 'default')};
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
  text-decoration: none;

  :hover {
    color: ${({ theme }) => theme.color.grey700};
  }

  :focus {
    outline: thin dotted ${({ theme }) => theme.color.grey500};
  }
`;

const FallbackLogo = () => (
  <WistiaLogoNew
    iconOnly={true}
    height={32}
    data-testid="wistia-logo"
  />
);

export const NameOrLogo = ({ account, logoMaxHeight }) => {
  if (account) {
    const { logoUrl, displayName } = account;

    if (logoUrl) {
      // a user can have a logo set without necessarily setting an account name
      const altMessage = displayName ? `${displayName} logo` : 'Account logo';

      return (
        <AccountLogo
          src={logoUrl}
          alt={altMessage}
          data-testid="custom-logo"
          maxHeight={logoMaxHeight}
        />
      );
    }

    if (displayName) {
      return (
        <AccountName
          variant="headline2"
          data-testid="account-name"
        >
          <Ellipsis>{displayName}</Ellipsis>
        </AccountName>
      );
    }

    return <FallbackLogo />;
  }

  return null;
};

export const AccountLogoLink = ({
  account,
  href = '/home',
  showFallbackLogo,
  justifyContent = 'left',
  logoMaxHeight,
  children = null,
  beforeAction = undefined,
}) => {
  return (
    <Container justifyContent={justifyContent}>
      <SiteName
        href={href}
        beforeAction={beforeAction}
      >
        {showFallbackLogo ? (
          <FallbackLogo />
        ) : (
          <NameOrLogo
            account={account}
            logoMaxHeight={logoMaxHeight}
          />
        )}
      </SiteName>
      {children}
    </Container>
  );
};
