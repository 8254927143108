import styled from 'styled-components';
import { Box, mq } from '@wistia/vhs';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE, HEADER_PADDING_RIGHT } from './constants';

export const HeaderContainer = styled(Box).attrs({ alignItems: 'center', renderAs: 'header' })`
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.shadow.elevation2};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  height: ${HEADER_HEIGHT}px;
  padding: 16px ${HEADER_PADDING_RIGHT}px 16px 24px;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.Header};

  ${mq.mdAndDown} {
    height: ${HEADER_HEIGHT_MOBILE}px;
    padding-right: 24px;
  }
`;
