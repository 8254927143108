import styled from 'styled-components';
import { Box, Link, mq } from '@wistia/vhs';
import { useLocation } from 'react-router-dom';
import type { JSX } from 'react';

const Tabs = styled(Box).attrs({
  gap: '8px',
})`
  border-bottom: 1px solid ${({ theme }) => theme.color.grey300};
  margin: 24px 16px 0;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  ${mq.smAndUp} {
    margin: 24px 0 0;
  }
`;

const StyledTab = styled(Link)<{ selected: boolean }>`
  color: ${({ selected, theme }) => (selected ? theme.color.brandBlue600 : theme.color.grey900)};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  padding: 0 8px;
  text-decoration: none;
  transition: color ${({ theme }) => theme.duration.expressive.fast};

  ${mq.smAndUp} {
    color: ${({ selected, theme }) => (selected ? theme.color.brandBlue500 : theme.color.grey900)};
    font-size: 16px;

    &:hover {
      color: ${({ theme }) => theme.color.brandBlue500};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.brandBlue600};
  }
`;

const TabHighlight = styled.div`
  background: ${({ theme }) => theme.color.brandBlue600};
  border-radius: 8px;
  height: 3px;
  width: 100%;

  ${mq.smAndUp} {
    background: ${({ theme }) => theme.color.brandBlue500};
  }
`;

const Tab = ({
  selected,
  label,
  href,
  onClick,
}: {
  selected: boolean;
  label: JSX.Element | string;
  href: string;
  onClick?: () => void;
}) => {
  return (
    <Box
      gap="3px"
      direction="column"
      style={{ flexShrink: 0 }}
    >
      <StyledTab
        selected={selected}
        data-testid={`screen-header-tab-${String(label)}`}
        data-selected={selected}
        href={href}
        beforeAction={onClick}
      >
        {label}
      </StyledTab>
      {selected ? <TabHighlight /> : null}
    </Box>
  );
};

export type TabInfo = {
  baseUrlPathSelectedComparator?: string;
  label: JSX.Element | string;
  to?: string;
  href?: string;
  track?: () => void;
};

export const NavigationTabs = ({ tabs }: { tabs: TabInfo[] }): JSX.Element => {
  const { pathname } = useLocation();
  return (
    <Box fill="horizontal">
      <Tabs>
        {tabs.map((tab, i) => {
          return (
            <Tab
              key={i} // eslint-disable-line react/no-array-index-key
              //  NOTE: Some tabs may need to be displayed as "selected" that have query params attached to the URL
              selected={pathname === tab.baseUrlPathSelectedComparator || pathname === tab.to}
              label={tab.label}
              href={tab.to ?? tab.href ?? ''}
              onClick={tab.track}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};
