import { lazyWithSuspense } from '~/utilities/lazyWithSuspense';
import { ScreenContainer } from '../../layouts/shared/ScreenContainer';
import { ScreenHeader } from '../../layouts/SidePanelLayout/ScreenHeader';

export const GroupRecordAnonymousStage = lazyWithSuspense(async () => import('./Stage'), {
  loadingState: (
    <>
      <ScreenHeader />
      <ScreenContainer />
    </>
  ),
});
