import { lazy, Suspense } from 'react';
import type { RoutableComponent } from '~/singlePageApp/App/routingHelpers';
import { dangerous_markComponentAsRoutable } from '~/singlePageApp/App/routingHelpers';

/**
 * This method converts an `import('./MyComponent')` promise into a renderable
 * component with a loading state. This is a wrapper around `lazy` and `Suspense`
 * from React but is offered as a convenience to easily hook up custom loadings states.
 *
 * For example, consider the following screen:
 * ```
 *   // in MyScreen/MyScreen.jsx
 *   import myFetchFunction from './myFetchFunction';
 *   import Loading from './Loading';
 *
 *   const MyScreen = () => {
 *     const { loading, data} = myFetchFunction;
 *
 *     if (loading) {
 *       return <Loading />
 *     }
 *
 *     return someJSX;
 *   };
 *   export default MyScreen;
 *
 *   // in MyScreen/index.js
 *   export { default } from './MyScreen';
 * ```
 *
 * If we wanted to make this screen lazy, we can modify the screens index.jsx page
 * as follows:
 *
 * ```
 *   // in MyScreeen/index.js
 *   import { lazyWithSuspense } from '/path/to/lazyWithSuspense';
 *   import Loading from './Loading';
 *
 *   const MyScreen = lazyWithSuspense(() => import('./MyScreen'), { loadingState: <Loading /> })
 *
 *   export default MyScreen;
 * ```
 *
 * While the screen code was loading, it would show the same loading state as when fetching
 * data from the server so the user experience would remain unchanged in most cases but
 * with the benefit of code splitting.
 */

type SuspenseOverloads = {
  <Props, Key extends string>(
    importPromiseCallback: () => Promise<Record<Key, React.ComponentType<Props>>>,
    options: {
      loadingState?: NonNullable<React.ReactNode>;
      exportKey: Key;
    },
  ): RoutableComponent<Props>;
  <Props>(
    importPromiseCallback: () => Promise<Record<'default', React.ComponentType<Props>>>,
    options?: {
      loadingState?: NonNullable<React.ReactNode>;
    },
  ): RoutableComponent<Props>;
};

export const lazyWithSuspense: SuspenseOverloads = <Props, Key extends string = 'default'>(
  importPromiseCallback: () => Promise<Record<Key, React.ComponentType<Props>>>,
  options?: {
    loadingState?: NonNullable<React.ReactNode>;
    exportKey?: Key;
  },
) => {
  const Component = lazy(async () => {
    const module = await importPromiseCallback();
    return {
      // @ts-expect-error if options?.export key is undefined,
      // then we know that the index is default due to the function overloads
      default: module[options?.exportKey ?? 'default'],
    };
  });

  const WrappedComponent = (
    props: React.PropsWithChildren<Props> &
      React.PropsWithRef<Props & { children?: React.ReactNode }>,
  ) => {
    return (
      <Suspense fallback={options?.loadingState ?? ''}>
        {}
        <Component {...props} />
      </Suspense>
    );
  };

  return dangerous_markComponentAsRoutable(WrappedComponent);
};
