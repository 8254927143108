import { isNull } from '~/utilities/type-guards';

export const getAuthenticityToken = (): string | undefined => {
  const metaTag = document.querySelector('meta[name="csrf-token"]');
  let csrfToken = metaTag?.getAttribute('content');

  if (isNull(csrfToken)) {
    csrfToken = undefined;
  }

  return csrfToken;
};
