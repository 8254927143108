import type { JSX } from 'react';
import { useRef, useState } from 'react';
import type { ServerError, ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { environment } from '~/constantDefinitions';
import { isNotNil } from '~/utilities/type-guards';
import { getApolloClient } from '~/singlePageApp/getApolloClient';

const useApolloClient = ({ schema }: { schema?: 'AnonymousSchema' | 'WistiaSchema' } = {}) => {
  const [networkError, setNetworkError] = useState<Error>();
  const [hasAnApiCallSucceeded, setHasAnApiCallSucceeded] = useState<boolean>();

  const setHasAnApiCallSucceededOnce = () => {
    if (hasAnApiCallSucceeded) {
      return;
    }

    setHasAnApiCallSucceeded(true);
  };

  const callbacks = {
    onNetworkError: setNetworkError,
    onSuccess: setHasAnApiCallSucceededOnce,
  };

  const client = useRef(getApolloClient({}, callbacks, schema));

  return { client: client.current, networkError, hasAnApiCallSucceeded };
};

const isServerError = (error: Error): error is ServerError => {
  return error.toString().includes('ServerError');
};

/**
 * Connects to either the WistiaSchema or AnonymousSchema. Ideally you have one provider per app.
 * Nested providers may have unexpected results.
 */
export const WistiaApolloProvider = ({
  children,
  currentClient,
  onApolloClientLoaded,
  schema,
}: {
  children: JSX.Element;
  currentClient?: ApolloClient<InMemoryCache>;
  onApolloClientLoaded?: (client: ApolloClient<InMemoryCache>) => void;
  schema?: 'AnonymousSchema' | 'WistiaSchema';
}): JSX.Element => {
  const { client: ourClient, networkError, hasAnApiCallSucceeded } = useApolloClient({ schema });

  if (isNotNil(networkError)) {
    // if we hit a 500, either display a notification or throw an error so the error
    // boundary triggers.
    // I cannot get this to work in the ErrorBoundary for some reason. React munges
    // the errors when caught so it has to be handled here unfortunately.
    if (isServerError(networkError) && environment === 'development') {
      const errorHTML = networkError.result as unknown as string; // apollo expects an object response on error but rails has other ideas so this is safe
      const virtualElement = document.createElement('div');
      virtualElement.innerHTML = errorHTML;

      // If this is specifically a migration error, we need to make
      // sure to redirect to the current SPA path and not the graphql
      // controller. Rails assumes the error will be from a get request.
      const afterMigrationPath = virtualElement
        .querySelector<HTMLInputElement>('input[value="Run pending migrations"]')
        ?.form?.querySelector<HTMLInputElement>('input[name="location"]');

      if (afterMigrationPath != null) {
        afterMigrationPath.value = window.location.pathname + window.location.search;
      }

      // Finally, render the HTML of our virtual element.
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: virtualElement.innerHTML }} />;
    }

    if (hasAnApiCallSucceeded) {
      // eslint-disable-next-line no-console
      console.error('Apollo Client encountered a network error:', networkError);
    } else {
      throw networkError;
    }
  }

  // NOTE: it is better to use the useApolloClient hook but this can be used
  // for legacy code where access to the client is needed outside of the
  // react hierarchy.
  if (isNotNil(onApolloClientLoaded)) {
    onApolloClientLoaded(ourClient as ApolloClient<InMemoryCache>);
  }

  return <ApolloProvider client={currentClient ?? ourClient}>{children}</ApolloProvider>;
};
