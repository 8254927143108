import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Text } from '@wistia/vhs';
import { environment } from '~/constantDefinitions';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError(error, errorInfo) {
    return { hasError: true, error, errorInfo };
  }

  render() {
    if (this.state.hasError) {
      if (environment === 'development') {
        return (
          <Box
            fillViewport={true}
            direction="column"
            style={{ margin: '50px' }}
          >
            <Text variant="headline2">There is a client-side error:</Text>
            <Text
              variant="monospace"
              style={{ marginTop: '25px', width: '960px' }}
            >
              {this.state.error?.toString()}
              {this.state.errorInfo?.toString()}
              {!this.state.errorInfo &&
                !this.state.error &&
                'Check the browser console for more info'}
            </Text>
          </Box>
        );
      }

      return (
        <Navigate
          replace={false}
          to="/500"
        />
      );
    }

    return this.props.children;
  }
}
