import { HelmetProvider } from 'react-helmet-async';
import { VHSProvider } from '@wistia/vhs';
import { WistiaProvider } from '@wistia/react-embeds';

import { wistiaTheme } from '~/singlePageApp/wistiaTheme';
import { WistiaApolloProvider } from '../../providers/WistiaApolloProvider';
import { BottlerApolloProvider } from '../../bottler';
import { GlobalStyle } from '../GlobalStyle';
import { fastBaseHostname } from '~/constantDefinitions';
import { FeatureFlagProvider } from '~/singlePageApp/providers/FeatureFlagProvider';
import { OnboardingProvider } from '~/onboarding/hooks/useOnboarding/OnboardingProvider';

export const AnonymousAppProviders = ({ children }) => {
  return (
    <WistiaApolloProvider schema="AnonymousSchema">
      <FeatureFlagProvider>
        <BottlerApolloProvider>
          <WistiaProvider origin={`https://${fastBaseHostname}`}>
            <VHSProvider theme={wistiaTheme}>
              <OnboardingProvider>
                <GlobalStyle />
                <HelmetProvider>{children}</HelmetProvider>
              </OnboardingProvider>
            </VHSProvider>
          </WistiaProvider>
        </BottlerApolloProvider>
      </FeatureFlagProvider>
    </WistiaApolloProvider>
  );
};
