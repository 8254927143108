import type { JSX } from 'react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import type { SupportedFeatureFlags } from '~/singlePageApp/hooks/useLoadFeatureFlags';
import { useLoadFeatureFlags } from '~/singlePageApp/hooks/useLoadFeatureFlags';

export type RedirectUnlessAllowedFeatureFlagsProps = {
  to: string;
  flag: SupportedFeatureFlags;
  children: JSX.Element;
  hardRedirect?: boolean;
  flagConditionToAllow?: boolean;
};

export const RedirectUnlessFeatureFlagEnabled = ({
  to,
  flag,
  children,
  hardRedirect = false,
  flagConditionToAllow = true,
}: RedirectUnlessAllowedFeatureFlagsProps): JSX.Element | null => {
  const { loading, flags } = useLoadFeatureFlags([flag]);

  const routeEnabled = flags[flag].enabled === flagConditionToAllow;

  useEffect(() => {
    if (!loading && !routeEnabled && hardRedirect) {
      window.location.assign(to);
    }
  }, [loading, routeEnabled, hardRedirect, to]);

  if (!loading && routeEnabled) {
    return children;
  }
  if (!loading && !routeEnabled) {
    if (hardRedirect) {
      return null;
    }

    return (
      <Navigate
        replace={true}
        to={to}
      />
    );
  }
  return null;
};
