import { AnonymousHeader } from '~/components/Header/AnonymousHeader';
import type { JSX } from 'react';
import { ScreenContainer } from '../../layouts/shared/ScreenContainer';

export const LoadingState = (): JSX.Element => {
  return (
    <>
      {/* @ts-expect-error improperly inferred prop */}
      <AnonymousHeader />
      <ScreenContainer />
    </>
  );
};
