import { useParams, Navigate } from 'react-router-dom';

export const RedirectUnlessAllowedParams = ({ to, allowedParams, children }) => {
  const params = useParams();

  const allParamsAllowed = Object.keys(allowedParams).every((param) => {
    const includes = allowedParams[param];
    const actual = params[param];

    return includes.includes(actual);
  });

  return allParamsAllowed ? (
    children
  ) : (
    <Navigate
      to={to}
      replace={true}
    />
  );
};
