import { isUndefined } from '~/utilities/type-guards';
import type { CallbackFn, ElementType, ObservedData } from './types';

const observedElements = new Map<ElementType, ObservedData>();
let animationFrameId = -1;

const rectEquals = (r1: DOMRect, r2: DOMRect) => {
  return (
    r1.width === r2.width &&
    r1.height === r2.height &&
    r1.top === r2.top &&
    r1.right === r2.right &&
    r1.bottom === r2.bottom &&
    r1.left === r2.left
  );
};

const runLoop = () => {
  const changedRectsData: ObservedData[] = [];

  observedElements.forEach((data, element) => {
    const newRect = element.getBoundingClientRect();

    if (!rectEquals(data.rect, newRect)) {
      // eslint-disable-next-line no-param-reassign
      data.rect = newRect;
      changedRectsData.push(data);
    }
  });

  changedRectsData.forEach((data) => {
    data.callbacks.forEach((callback) => callback(data.rect));
  });

  animationFrameId = requestAnimationFrame(runLoop);
};

const unobserveElementRect = (element: ElementType, callback: CallbackFn) => {
  const observedData = observedElements.get(element);
  if (isUndefined(observedData)) {
    return;
  }

  const callbackIndex = observedData.callbacks.indexOf(callback);
  if (callbackIndex > -1) {
    observedData.callbacks.splice(callbackIndex, 1);
  }

  if (observedData.callbacks.length === 0) {
    observedElements.delete(element);
  }

  if (observedElements.size === 0) {
    cancelAnimationFrame(animationFrameId);
  }
};

export const observeElementRect = (element: ElementType, callback: CallbackFn): (() => void) => {
  const observedData = observedElements.get(element);

  if (isUndefined(observedData)) {
    observedElements.set(element, { rect: {} as DOMRect, callbacks: [callback] });
  } else {
    observedData.callbacks.push(callback);
    callback(element.getBoundingClientRect());
  }

  if (observedElements.size === 1) {
    animationFrameId = requestAnimationFrame(runLoop);
  }

  return () => unobserveElementRect(element, callback);
};
