import styled from 'styled-components';
import { mq } from '@wistia/vhs';
import { gql, useQuery } from '@apollo/client';
import { isNotNil } from '~/utilities/type-guards';
import { HeaderContainer } from './HeaderContainer';
import { AccountLogoLink } from './AccountLogoLink';
import { EnvironmentLabel } from './EnvironmentLabel';
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from './constants';

const UNAUTHENTICATED_QUERY = gql`
  query UnauthenticatedHeaderInfo {
    currentAccount {
      id
      logoUrl
      displayName
    }
  }
`;

/*
 * Here's a guide to the anonymous header's grid layout.
 *
 * We use numbered `grid-area` bounds in the `*Cell` components below. This suppresses the second
 * row and the gap above it on mobile when free branding is not shown. If we used
 * `grid-template-areas` to name these areas, the second row and the row gap would always be shown
 * even when empty.
 *
 * Mobile:
 *
 *       1                  2                  3
 *     1 +------------------+------------------+
 *       |                  |                  |
 *     2 +------------------+------------------+
 *       |xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx| space04 gap }
 *     2 +------------------+------------------+             } Omitted when branding not present
 *       |                  |                  |             } (e.g. for media in paid accounts)
 *     3 +------------------+------------------+             }
 *
 * Desktop:
 *
 *       1                      2                      3                      4
 *     1 +----------------------+----------------------+----------------------+
 *       |                      |                      |                      |
 *     2 +----------------------+----------------------+----------------------+
 *
 */
const AnonymousHeaderContainer = styled(HeaderContainer)`
  display: grid;
  grid-template-columns: auto auto;
  height: max-content;
  justify-content: stretch;
  max-width: 100%;
  min-height: ${HEADER_HEIGHT_MOBILE}px;
  overflow: hidden;
  row-gap: ${({ theme }) => theme.spacing.space04};

  ${mq.mdAndUp} {
    grid-template-columns: 1fr max-content 1fr;
    min-height: ${HEADER_HEIGHT}px;
  }
`;

/*
 * Mobile:
 *
 *       1                  2                  3
 *     1 +------------------+------------------+
 *       |<~~~~~ Here ~~~~~>|                  |
 *     2 +------------------+------------------+
 *       |xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|
 *     2 +------------------+------------------+
 *       |                  |                  |
 *     3 +------------------+------------------+
 *
 * Desktop:
 *
 *       1                      2                      3                      4
 *     1 +----------------------+----------------------+----------------------+
 *       |<~~~~~~~ Here ~~~~~~~>|                      |                      |
 *     2 +----------------------+----------------------+----------------------+
 *
 */
const AccountCell = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  overflow: hidden; /* ensure long account names are ellipsized */
`;

/*
 * Mobile:
 *
 *       1                  2                  3
 *     1 +------------------+------------------+
 *       |                  |                  |
 *     2 +------------------+------------------+
 *       |xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|
 *     2 +------------------+------------------+
 *       |<~~~~~~~~~~~~~ H e r e ~~~~~~~~~~~~~>|
 *     3 +------------------+------------------+
 *
 * Desktop:
 *
 *       1                      2                      3                      4
 *     1 +----------------------+----------------------+----------------------+
 *       |                      |<~~~~~~~ Here ~~~~~~~>|                      |
 *     2 +----------------------+----------------------+----------------------+
 *
 */
export const BrandingCell = styled.div`
  grid-area: 2 / 1 / 3 / 3;

  ${mq.mdAndUp} {
    grid-area: 1 / 2 / 2 / 3;
  }
`;

/*
 * Mobile:
 *
 *       1                  2                 3
 *     1 +------------------+------------------+
 *       |                  |<~~~~~ Here ~~~~~>|
 *     2 +------------------+------------------+
 *       |xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|
 *     2 +------------------+------------------+
 *       |                  |                  |
 *     3 +------------------+------------------+
 *
 * Desktop:
 *
 *       1                      2                      3                      4
 *     1 +----------------------+----------------------+----------------------+
 *       |                      |                      |<~~~~~~~ Here ~~~~~~~>|
 *     2 +----------------------+----------------------+----------------------+
 *
 */
export const ActionsCell = styled.div`
  display: flex;
  grid-area: 1 / 2 / 2 / 3;
  justify-content: flex-end;

  ${mq.mdAndUp} {
    grid-area: 1 / 3 / 2 / 4;
  }
`;

export const AnonymousHeader = ({ children, shouldShowFallbackLogo = false }) => {
  const { data, error } = useQuery(UNAUTHENTICATED_QUERY);

  return (
    <AnonymousHeaderContainer>
      <AccountCell>
        <AccountLogoLink
          account={data?.currentAccount}
          showFallbackLogo={isNotNil(error) || shouldShowFallbackLogo}
        >
          <EnvironmentLabel />
        </AccountLogoLink>
      </AccountCell>

      {isNotNil(children) && children}
    </AnonymousHeaderContainer>
  );
};
