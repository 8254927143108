import { millisecondsInMinute } from 'date-fns/constants';
import { stringHelpers } from '@wistia/vhs';
import { isString, isNotNil } from '~/utilities/type-guards';

const { coerceToBoolean } = stringHelpers;

const HOSTNAME = {
  development: isNotNil(process.env.BASE_HOSTNAME) ? process.env.BASE_HOSTNAME : 'wistia.io',
  staging: 'wistia.st',
  stagingBranchDeploy: 'wistia.st',
  production: 'wistia.com',
};

const BOTTLER_HOST = {
  development: `bottler.${HOSTNAME.development}`,
  staging: 'bottler.wistia.st',
  stagingBranchDeploy: 'bottler.wistia.st',
  production: 'bottler.wistia.com',
};

const EMBED_HOST = {
  development: `embed.${HOSTNAME.development}`,
  staging: 'embed.wistia.st',
  stagingBranchDeploy: 'embed.wistia.st',
  production: 'embed.wistia.com',
};

const DYNAMIC_AKAMAI_SSL_EMBED_HOST = {
  development: 'embed-wistia-staging.akamaized.net',
  staging: 'embedwistia-a.akamaihd.net',
  stagingBranchDeploy: 'embedwistia-a.akamaihd.net',
  production: 'embedwistia-a.akamaihd.net',
};

const METRICS_HOST = {
  development: `pipedream.${HOSTNAME.development}`,
  staging: 'pipedream.wistia.st',
  stagingBranchDeploy: 'pipedream.wistia.st',
  production: 'pipedream.wistia.com',
};

const ANALYTICS_HOST = {
  development: `distillery.${HOSTNAME.development}`,
  staging: 'distillery.wistia.st',
  stagingBranchDeploy: 'distillery.wistia.st',
  production: 'distillery.wistia.net',
};

const FLAGSMITH_ENVIRONMENT_ID = {
  development: 'i3DnVi4UWqxt9SKYn6YG8n',
  staging: 'BZNnH2EVAP7vTGiKrxaGRC',
  stagingBranchDeploy: 'BZNnH2EVAP7vTGiKrxaGRC',
  production: 'BsXmVxDQF9rjN8k2EHzWdp',
  test: 'hh5Z8mXXjX7UGWPHgdheMj',
};

const FLAGSMITH_ANONYMOUS_ENVIRONMENT_ID = {
  development: 'YmqrVNta2M6RxMxj5577cN',
  staging: 'ZXtVm6c8y3msKTcPmicbVa',
  stagingBranchDeploy: 'ZXtVm6c8y3msKTcPmicbVa',
  production: '4ajAh5C4jsTyvU2fvp9xyz',
  test: 'TMKXQxN6nmp5GpWbYayMdW',
};

const FLAGSMITH_V3_ENVIRONMENT_ID = {
  development: 'Q5QYXdxgsmgzfSeDxrWXEi',
  staging: 'TDGaJQb6pyL2LJpdkcKKPR',
  stagingBranchDeploy: 'TDGaJQb6pyL2LJpdkcKKPR',
  production: 'CnnRjB6XdtBgfNrciMsrBp',
  test: '',
};

const FLAGSMITH_PROJECT_ID = '7030';
const FLAGSMITH_V3_PROJECT_ID = '19661';

const FLAG_CACHE_TTL_MS = {
  development: Number(process.env.FLAG_CACHE_TTL_SEC) * 1000 || millisecondsInMinute, // 1 minute or allow for override
  staging: millisecondsInMinute,
  stagingBranchDeploy: millisecondsInMinute,
  production: millisecondsInMinute,
};

const INTEGRATIONS_MARKETO_CLIENT_ID = {
  development: '2fb0469b-1b8f-4e8c-a667-2ec4aed1e972',
  staging: '',
  stagingBranchDeploy: '',
  production: '',
};

const INTEGRATIONS_MARKETO_ENDPOINT = {
  development: 'https://354-URC-800.mktorest.com/rest',
  staging: '',
  stagingBranchDeploy: '',
  production: '',
};

const INTEGRATIONS_MARKETO_IDENTITY = {
  development: 'https://354-URC-800.mktorest.com/identity',
  staging: '',
  stagingBranchDeploy: '',
  production: '',
};

const INTEGRATIONS_PARDOT_USERNAME = {
  development: 'integrationsdev@wistia.com',
  staging: '',
  stagingBranchDeploy: '',
  production: '',
};

const PENDO_API_KEY = '004aa220-e830-47ce-67b9-3e295aa16b40';

const PLAID_ENV = {
  development: 'sandbox',
  staging: 'sandbox',
  stagingBranchDeploy: 'sandbox',
  production: 'production',
};

const PORT = 80;

const PLAID_PUBLIC_KEY = '3b79d0315d21db7881bc5a985ccd24';

const SSL_EMBED_HOST = {
  development: 'embed-cloudfront.wistia.st',
  staging: 'embed-cloudfront.wistia.st',
  stagingBranchDeploy: 'embed-cloudfront.wistia.st',
  production: 'embed-ssl.wistia.com',
};

const STRIPE_PUBLIC_KEY = {
  development: 'pk_test_2Dt52xjq2MEccyO4YJNGnNT100Zp1VSj49',
  staging: 'pk_test_2Dt52xjq2MEccyO4YJNGnNT100Zp1VSj49',
  stagingBranchDeploy: 'pk_test_2Dt52xjq2MEccyO4YJNGnNT100Zp1VSj49',
  production: 'pk_live_LM3O5DtrJvDAxXYYOdBb4Br7001VnXKEff',
};

const AGORA_APP_ID = {
  development: '210cef8bce134582867ef72dde5a13ca',
  staging: '210cef8bce134582867ef72dde5a13ca',
  stagingBranchDeploy: '210cef8bce134582867ef72dde5a13ca',
  production: 'df41aaccee6e4156b5a574d31c0bff23',
};

const STREAM_CHAT_KEY = {
  development: 'wywtymsntreu',
  staging: 'wywtymsntreu',
  stagingBranchDeploy: 'wywtymsntreu',
  production: 'rjjpq7dn4rep',
};

const NOTICEABLE_ID = {
  development: '70EFuoYMRF4n3yrJu82D',
  staging: '70EFuoYMRF4n3yrJu82D',
  stagingBranchDeploy: '70EFuoYMRF4n3yrJu82D',
  production: '70EFuoYMRF4n3yrJu82D',
};

const GOOGLE_RECAPTCHA_KEY = '6Lc7aosnAAAAAPxNlMTadqKVvKPNVt7PX9XiiNU2';

const REGEX_FOR_BRANCH_DEPLOY = /.(branch-[\w\d\-_]+).wistia.(st|io)/i;

const getWistiaDomainWithoutSubdomain = (url: string): string => {
  const urlParts = new URL(url).hostname.split('.');
  const tld = urlParts.pop();
  const domain = urlParts.pop();

  return `${domain}.${tld}`;
};

type Environment = 'development' | 'production' | 'staging' | 'stagingBranchDeploy';

export const getEnvironment = (): Environment => {
  const { hostname } = window.location;
  if (hostname.endsWith('wistia.com')) {
    return 'production';
  }

  if (hostname.endsWith('wistia.st')) {
    if (REGEX_FOR_BRANCH_DEPLOY.exec(hostname)) {
      return 'stagingBranchDeploy';
    }
    return 'staging';
  }

  if (/wistia.(io|am|se|mx|tech|dev)$/.exec(hostname)) {
    return 'development';
  }

  return 'production';
};

export const getHostname = (): string => {
  return HOSTNAME[getEnvironment()];
};

const getSubdomain = (): string => {
  const url = window.location.href;
  const urlParts = new URL(url).hostname.split('.');
  return urlParts[0];
};

export const getSuffixedHostname = (): string => {
  let branchSuffix = '';
  const env = getEnvironment();
  const domain = getHostname();
  if (env === 'stagingBranchDeploy') {
    const { hostname } = window.location;
    const matches = REGEX_FOR_BRANCH_DEPLOY.exec(hostname);
    if (matches) {
      branchSuffix = matches[1]; // eslint-disable-line @typescript-eslint/prefer-destructuring
      return `-${branchSuffix}.${domain}`;
    }
  }
  return `.${domain}`;
};

const getAuthUrl = (): string | null => {
  const env = getEnvironment();
  const hostname = getHostname();

  if (env === 'development') {
    const useAuthService = coerceToBoolean(process.env.USE_AUTH_SERVICE);
    const wistiaAuthSite = process.env.WISTIA_AUTH_SITE;

    if (useAuthService && isString(wistiaAuthSite)) {
      return wistiaAuthSite;
    }

    return null;
  }

  return `https://auth.${hostname}`;
};

export const environment = getEnvironment();

export const hostname = getHostname();

export const subdomain = getSubdomain();

export const suffixedHostname = getSuffixedHostname();

export const bottlerHost = BOTTLER_HOST[getEnvironment()];

export const dataApiHost = `api.${getHostname()}`;

export const embedHost = EMBED_HOST[getEnvironment()];

export const dynamicAkamaiSslEmbedHost = DYNAMIC_AKAMAI_SSL_EMBED_HOST[getEnvironment()];

export const fastHostname = `fast.${getHostname()}`;

export const fastBaseHostname = `fast.${getWistiaDomainWithoutSubdomain(window.location.href)}`;

export const appHost = `app${getSuffixedHostname()}`;

export const authUrl = getAuthUrl();

export const integrationsMarketoClientId = INTEGRATIONS_MARKETO_CLIENT_ID;

export const integrationsMarketoEndpoint = INTEGRATIONS_MARKETO_ENDPOINT;

export const integrationsMarketoIdentity = INTEGRATIONS_MARKETO_IDENTITY;

export const integrationsPardotUsername = INTEGRATIONS_PARDOT_USERNAME;

export const flagsmithEnvironmentId = FLAGSMITH_ENVIRONMENT_ID[getEnvironment()];

export const flagsmithAnonymousEnvironmentId = FLAGSMITH_ANONYMOUS_ENVIRONMENT_ID[getEnvironment()];

export const flagsmithV3EnvironmentId = FLAGSMITH_V3_ENVIRONMENT_ID[getEnvironment()];

export const flagsmithProjectId = FLAGSMITH_PROJECT_ID;
export const flagsmithV3ProjectId = FLAGSMITH_V3_PROJECT_ID;

export const flagCacheTTL = FLAG_CACHE_TTL_MS[getEnvironment()];

export const metricsHost = METRICS_HOST[getEnvironment()];

export const pendoApiKey = PENDO_API_KEY;

export const plaidEnv = PLAID_ENV[getEnvironment()];

export const plaidPublicKey = PLAID_PUBLIC_KEY;

export const port = PORT;

export const sslEmbedHost = SSL_EMBED_HOST[getEnvironment()];

export const stripePublicKey = STRIPE_PUBLIC_KEY[getEnvironment()];

export const agoraAppId = AGORA_APP_ID[getEnvironment()];

export const streamChatKey = STREAM_CHAT_KEY[getEnvironment()];

export const analyticsHost = ANALYTICS_HOST[getEnvironment()];

export const noticeableId = NOTICEABLE_ID[getEnvironment()];

export const googleRecaptchaKey = GOOGLE_RECAPTCHA_KEY;
