import type { RangeSelector } from '@wistia/vhs';
import {
  addDays,
  startOfDay,
  startOfMonth,
  subMonths,
  startOfWeek,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import type { ComponentProps } from 'react';

const TWO_YEARS_AGO_IN_DAYS = -730;
const SEVEN_DAY_DIFFERENCE = -6;
const TWENTY_EIGHT_DAY_DIFFERENCE = -27;
const NINETY_DAY_DIFFERENCE = -89;
const SIX_MONTHS = 6;
const THIRTY_DAY_DIFFERENCE = -29;
const LAST_365_DAY_DIFFERENCE = -364;
const TEN_YEARS_AGO_IN_DAYS = -3650;
export const TWO_WEEKS = 14;
export const TWO_MONTHS = 60;

export const EARLIEST_ALLOWED_DATE = startOfDay(addDays(new Date(), TWO_YEARS_AGO_IN_DAYS));
export const EARLIEST_ALLOWED_EMBED_LOCATION_DATE = startOfDay(subMonths(new Date(), SIX_MONTHS));
const YEAR_2020 = 2020;
const November = 10;
export const START_OF_NEW_ANALYTICS = new Date(YEAR_2020, November, 1, 0, 0, 0);
export const TEN_YEARS_AGO = startOfDay(addDays(new Date(), TEN_YEARS_AGO_IN_DAYS));
// we allow querying from Aug 1, 2023 onwards (7 means August because JS starts month counting at 0)
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const EARLIEST_ALLOWED_CONVERSIONS_DATE = new Date(2023, 7, 1, 0, 0, 0);

export const today = new Date();

export type Presets = ComponentProps<typeof RangeSelector>['presets'];

const currentMonth = today.getMonth();
const currentYear = today.getFullYear();
const LAST_7_DAYS_PRESET = {
  label: 'Last 7 days',
  value: { end: today, start: addDays(today, SEVEN_DAY_DIFFERENCE) },
};
const LAST_28_DAYS_PRESET = {
  label: 'Last 28 days',
  value: { end: today, start: addDays(today, TWENTY_EIGHT_DAY_DIFFERENCE) },
};
const LAST_90_DAYS_PRESET = {
  label: 'Last 90 days',
  value: { end: today, start: addDays(today, NINETY_DAY_DIFFERENCE) },
};
const THIS_MONTH_PRESET = {
  label: 'This month',
  value: { end: today, start: startOfMonth(new Date(currentYear, currentMonth)) },
};
const LAST_30_DAYS_PRESET = {
  label: 'Last 30 days',
  value: { end: today, start: startOfDay(addDays(today, THIRTY_DAY_DIFFERENCE)) },
};
const LAST_365_DAYS_PRESET = {
  label: 'Last 365 days',
  value: { end: today, start: startOfDay(addDays(today, LAST_365_DAY_DIFFERENCE)) },
};
const THIS_WEEK_PRESET = {
  label: 'This week',
  value: { end: today, start: startOfWeek(today) },
};
const THIS_QUARTER_PRESET = {
  label: 'This quarter',
  value: { end: today, start: startOfQuarter(today) },
};
const THIS_YEAR_PRESET = {
  label: 'This year',
  value: { end: today, start: startOfYear(today) },
};

export const FILTER_DATE_PRESETS = [
  LAST_7_DAYS_PRESET,
  LAST_28_DAYS_PRESET,
  LAST_90_DAYS_PRESET,
  THIS_MONTH_PRESET,
];

export const ANALYTICS_FILTER_DATE_PRESETS = [
  LAST_7_DAYS_PRESET,
  LAST_30_DAYS_PRESET,
  LAST_90_DAYS_PRESET,
  LAST_365_DAYS_PRESET,
  THIS_WEEK_PRESET,
  THIS_MONTH_PRESET,
  THIS_QUARTER_PRESET,
  THIS_YEAR_PRESET,
];
