import { environment } from '../constantDefinitions';
import { tryCatch } from './util';

export interface TrackStructEventParams {
  category: string;
  action: string;
  label?: string | null;
  property?: string;
  value?: number | string | null;
}

// Snowplow is loaded via Google Tag Manager. See ApplicationHelper#google_tag_manager_js.

const shouldLog =
  (environment === 'development' ||
    environment === 'staging' ||
    environment === 'stagingBranchDeploy') &&
  process.env.NODE_ENV !== 'test';

export const trackStructEvent = ({
  category,
  action,
  label = null,
  property,
  value = null,
}: TrackStructEventParams): void => {
  if (window.snowplow) {
    tryCatch({
      fn: () => {
        // @ts-expect-error we have checked that window.snowplow is not undefined by this point.
        window.snowplow('trackStructEvent', category, action, label, property, value);
      },
      errorFn: (error) => {
        console.error(error); // eslint-disable-line no-console
      },
    });
  } else if (shouldLog) {
    // eslint-disable-next-line no-console, prettier/prettier
    console.warn(`Snowplow - Event not sent:\n ${JSON.stringify({ category, action, label, property, value }, null, 2)}\n`);
  } else {
    // Do nothing
  }
};
