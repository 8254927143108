import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// use this to update and retrieve search params.
//
// example - set a param
//
// const [searchParams, setSearchParams] = useSearchParams();
// searchParams.set({ foo: 'bar' }); // update url to container ?foo=bar
// searchParams.get('foo'); // bar
//
// example - just get a param
//
// (assuming the current URL is http://example.com?foo=bar)
// const [searchParams, setSearchParams] = useSearchParams();
// searchParams.get('foo'); // bar

type URLSearchParamsConstructorArgs = Record<string, string> | URLSearchParams | string;

export const useSearchParams = (): [
  URLSearchParams,
  (paramsObject?: URLSearchParamsConstructorArgs) => void,
  ({
    add,
    remove,
    navigateOptions,
  }: {
    add?: Record<string, string> | undefined;
    remove?: unknown[] | undefined;
    navigateOptions?: Record<string, string> | undefined;
  }) => void,
] => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const paramsAsString = params.toString();

  const setParams = (paramsObject?: URLSearchParamsConstructorArgs) => {
    navigate({
      search: new URLSearchParams(paramsObject).toString(),
    });
  };

  const changeParams = useCallback(
    ({ add = {}, remove = [], navigateOptions = {} }) => {
      const nextParams = new URLSearchParams(paramsAsString);
      Object.entries(add).forEach(([key, val]: [string, string]) => {
        if (Array.isArray(val)) {
          val.forEach((arrayEntry: string, index) => {
            if (index === 0) {
              nextParams.set(key, arrayEntry);
            } else {
              nextParams.append(key, arrayEntry);
            }
          });
        } else {
          nextParams.set(key, val);
        }
      });

      remove.forEach((key) => {
        nextParams.delete(key);
      });

      navigate(
        {
          search: nextParams.toString(),
          hash: window.location.hash,
        },
        navigateOptions,
      );
    },
    [navigate, paramsAsString],
  );

  return [params, setParams, changeParams];
};
