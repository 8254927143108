import { useMemo } from 'react';
import type { Duration } from 'date-fns';
import { add, endOfDay } from 'date-fns';
import { dateOnlyISOString } from '~/singlePageApp/utilities/dateStrings';
import { today } from '~/singlePageApp/constants/stats';

const FROM_SEVEN_DAYS_AGO: Duration = { days: -6 };
const TO_TODAY: Duration = { days: 1 };

export interface Interval {
  start: string;
  end: string;
}

export const useInterval = (
  from: Duration = FROM_SEVEN_DAYS_AGO,
  to: Duration = TO_TODAY,
): Interval =>
  useMemo(
    () => ({
      start: dateOnlyISOString(add(today, from), { timeZone: 'EST' }),
      end: dateOnlyISOString(endOfDay(add(today, to)), { timeZone: 'EST' }),
    }),
    [from, to],
  );
