import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { matchRoutes } from 'react-router-dom';
import { isNotNil } from '~/utilities/type-guards';
import { bottlerHost } from '../../constantDefinitions';

const hostParts = window.location.host.split('.');
const indexOfDomain = hostParts.indexOf('wistia');
const subdomain = hostParts.splice(0, indexOfDomain).join('.');

const getReferrerPathGroup = () => {
  const routes = [
    { path: '/account/analytics' },
    { path: '/account/analytics/compare' },
    { path: '/medias/:id/analytics' },
    { path: '/medias/:id/analytics/podcast' },
    { path: '/medias/:id/analytics/event_recap' },
    { path: '/channels/:id/stats' },
    { path: '/projects/:id/analytics' },
    { path: '/content/media' },
    { path: '/home' },
  ];

  const match = matchRoutes(routes, window.location);

  if (!match) {
    return '';
  }

  return match[0].route.path ?? '';
};

const customFetch = async (uri: string, options: Record<string, unknown>) => {
  const headers = options.headers as Record<string, string>;
  const newOptions = {
    ...options,
    headers: {
      ...headers,
      'x-referrer': document.location.href,
      'x-referrer-path-group': getReferrerPathGroup(),
    },
  };
  return fetch(uri, newOptions);
};

const link = new HttpLink({
  fetch: customFetch,
  uri: `https://${bottlerHost}`,
  credentials: 'include',
  headers: {
    'X-CSRF-TOKEN': isNotNil(window._auth_token) ? window._auth_token : '',
    'x-subdomain': subdomain,
  },
});

export const bottlerClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          mediaEvents: {
            keyArgs: ['interval', 'searchFilter', '@connection', ['key']],
          },
          medias: {
            keyArgs: ['searchFilter', '@connection', ['key']],
          },
          projectsCount: {
            keyArgs: ['@connection', ['key']],
          },
          channelsCount: {
            keyArgs: ['@connection', ['key']],
          },
          mediaFormConversions: {
            keyArgs: ['mediaGlobalId', 'interval', '@connection', ['key']],
          },
          liveEvent: {
            keyArgs: ['liveStreamEventId', '@connection', ['key']],
          },
          relativeMediaEvents: {
            keyArgs: ['relativePeriod', 'searchFilter', '@connection', ['key']],
          },
          liveEvents: {
            keyArgs: ['liveStreamEventIds', '@connection', ['key']],
          },
        },
      },
    },
  }),
  link,
});
