import { lazyWithSuspense } from '~/utilities/lazyWithSuspense';
import { LoadingState } from './LoadingState';

export const FolderInvitation = lazyWithSuspense(async () => import('./FolderInvitationScreen'), {
  loadingState: <LoadingState />,
  exportKey: 'FolderInvitationScreen',
});

export const ChannelInvitation = lazyWithSuspense(async () => import('./ChannelInvitationScreen'), {
  loadingState: <LoadingState />,
  exportKey: 'ChannelInvitationScreen',
});
