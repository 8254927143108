import type { Product, Pillar } from '../../types/wistia';

export interface WistiaErrorProperties {
  name: string;
  message: string;
  product: Product;
  pillar: Pillar;
}

export class WistiaError extends Error {
  public readonly product: Product;

  public readonly pillar: Pillar;

  public constructor({ name, message, product, pillar }: WistiaErrorProperties) {
    super(message);
    this.name = name;
    this.product = product;
    this.pillar = pillar;
  }
}

export const isWistiaError = (arg: unknown): arg is WistiaError => {
  return arg instanceof WistiaError;
};
