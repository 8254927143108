import type { ReactNode, JSX } from 'react';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import type { Nilable } from '~/utilities/type-guards';
import { isNil, isNonEmptyString, isNotNil } from '~/utilities/type-guards';
import type { StorageOptions, SupportedFeatureIds } from '~/onboarding/types';
import { activeOnboarding } from '~/onboarding/activeOnboarding';
import type { StorageService } from '~/onboarding/services/useStorageServices';
import { useStorageServices } from '~/onboarding/services/useStorageServices';
import { TargetPortal } from '~/onboarding/components/TargetPortal';
import { useForceUpdate } from '@wistia/vhs';
import { OnboardingContext } from './OnboardingContext';
import { SnowplowContext } from '../useSnowplow/SnowplowContext';

export type BackfillUtils = { getItem: () => Nilable<unknown>; removeItem: () => void };

export type OnboardingOptions = {
  allAtOnce?: boolean; // Display all steps at once or in succession. Default: false
  hideOnUnmount?: boolean; // Whether or not to hide/end the onboarding experience when useOnboarding unmounts. Default: true
  showImmediately?: boolean; // Show the onboarding experience immediately when `configure` is called. Default: false
  storage: StorageOptions; // How data should be stored
  stealFocus?: boolean; // Steal focus from any other onboarding experiences currently displayed
  backfillUtils?: BackfillUtils;
};

const DEFAULT_OPTIONS: Pick<OnboardingOptions, 'hideOnUnmount'> = {
  hideOnUnmount: true,
};

export type Experience = OnboardingOptions & {
  steps: React.ReactElement[];
  storageService: StorageService;
};

export type OnboardingConfiguration = {
  featureId: SupportedFeatureIds;
  steps: React.ReactElement[];
  options: OnboardingOptions;
};

export const OnboardingProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const experiencesRef = useRef<Partial<Record<SupportedFeatureIds, Experience>>>({});
  const currentFeatureIdRef = useRef<SupportedFeatureIds | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const forceUpdate = useForceUpdate();

  const storageServices = useStorageServices();
  const storageServiceByOption: Record<StorageOptions['type'], StorageService> = useMemo(
    () => ({
      none: storageServices.none,
      server: storageServices.server,
    }),
    [storageServices.none, storageServices.server],
  );

  const getExperienceById = useCallback((id?: SupportedFeatureIds) => {
    return isNonEmptyString(id) && id in experiencesRef.current ? experiencesRef.current[id] : null;
  }, []);

  const getCurrentExperience = useCallback(
    () => getExperienceById(currentFeatureIdRef.current),
    [getExperienceById],
  );

  const tryBackfill = useCallback(
    async (experience: Experience, featureId: SupportedFeatureIds) => {
      const { storageService, backfillUtils } = experience;

      if (isNil(backfillUtils)) {
        return false;
      }

      const backfillResponse = await storageService.backfillOnboardingActivity?.(
        featureId,
        backfillUtils,
      );

      return backfillResponse?.backfilled;
    },
    [],
  );

  const shouldShowCurrentExperience = useCallback(
    async (featureId: SupportedFeatureIds) => {
      try {
        const experience = getExperienceById(featureId);

        if (isNil(experience)) {
          return false;
        }

        if (await tryBackfill(experience, featureId)) {
          return false;
        }

        const activities = await experience.storageService.getOnboardingActivities(featureId);
        return !activities || activities.length === 0;
      } catch (_error) {
        throw Error(`featureId '${featureId}' does not exist`);
      }
    },
    [getExperienceById, tryBackfill],
  );

  const hasConflict = useCallback(() => isNotNil(currentFeatureIdRef.current), []);

  const show = useCallback(
    async (featureId: SupportedFeatureIds) => {
      const shouldShow = await shouldShowCurrentExperience(featureId);

      if (!shouldShow) {
        // TODO: need to update to log message here explaining why show doesn't work
        // https://app.shortcut.com/wistia-pde/story/34952/expand-uselogger-hook-so-it-can-be-used-in-onboardingprovider

        // eslint-disable-next-line no-console
        console.warn(`Skipping show ${featureId}`);
        return;
      }

      if (featureId in experiencesRef.current) {
        if (hasConflict()) {
          // eslint-disable-next-line no-console
          console.warn(
            `Another onboarding experience is already shown. Skipping show ${featureId}`,
          );
          return;
        }

        currentFeatureIdRef.current = featureId;
        setCurrentIndex(0);

        const experience = getExperienceById(featureId);

        if (isNotNil(experience) && experience.storage.activities?.experience.view === 'start') {
          void experience.storageService.createOnboardingActivity('view', featureId);
        }
        forceUpdate();
      } else {
        throw Error(`featureId '${featureId}' does not exist`);
      }
    },
    [getExperienceById, hasConflict, shouldShowCurrentExperience, forceUpdate],
  );

  const hide = useCallback(
    (featureId: SupportedFeatureIds) => {
      if (featureId in experiencesRef.current) {
        setCurrentIndex(0);
        currentFeatureIdRef.current = undefined;
      }
      // Hiding experiences that don't exist is not a bug and can be expected if
      // this is called in a useEffect where effects can run out of order
    },
    [setCurrentIndex],
  );

  const configure = useCallback(
    ({ featureId, steps, options }: OnboardingConfiguration) => {
      if (isNotNil(experiencesRef.current[featureId])) {
        return;
      }
      const experienceOptions = {
        ...DEFAULT_OPTIONS,
        ...options,
        steps,
        storageService: storageServiceByOption[options.storage.type],
      };

      // For server storage we default to saving the view event at the start of the experience
      if (
        experienceOptions.storage.type === 'server' &&
        isNil(experienceOptions.storage.activities)
      ) {
        experienceOptions.storage.activities = {
          experience: { view: 'start' },
        };
      }

      experiencesRef.current[featureId] = experienceOptions;

      if (experienceOptions.showImmediately) {
        void show(featureId);
      }
    },
    [show, storageServiceByOption],
  );

  const next = useCallback(
    (featureId: SupportedFeatureIds) => {
      const experience = getExperienceById(featureId);
      if (!experience || experience.allAtOnce) {
        return;
      }

      setCurrentIndex((current) => Math.min(current + 1, experience.steps.length - 1));
    },
    [getExperienceById],
  );

  const prev = useCallback(
    (featureId: SupportedFeatureIds) => {
      const experience = getExperienceById(featureId);
      if (!experience || experience.allAtOnce) {
        return;
      }

      setCurrentIndex((current) => Math.max(current - 1, 0));
    },
    [getExperienceById],
  );

  const value = useMemo(
    () => ({
      configure,
      show,
      next,
      prev,
      setCurrentIndex,
      currentIndex,
      getCurrentExperience,
      getCurrentFeatureId: () => currentFeatureIdRef.current,
      hide,
    }),
    [configure, show, next, prev, currentIndex, hide, getCurrentExperience],
  );

  useEffect(() => {
    const featureId = currentFeatureIdRef.current;

    if (isNotNil(featureId)) {
      const experience = getExperienceById(featureId);

      if (isNotNil(experience) && isNotNil(experience.steps)) {
        const lastStep = experience.steps.length - 1;

        if (currentIndex === lastStep && experience.storage.activities?.experience.view === 'end') {
          void experience.storageService.createOnboardingActivity('view', featureId);
        }
      }
    }
  }, [currentIndex, getExperienceById]);

  const snowplowValue = useMemo(
    () => ({
      label: isNil(currentFeatureIdRef.current)
        ? undefined
        : activeOnboarding[currentFeatureIdRef.current].id,
      step: getCurrentExperience()?.allAtOnce ? undefined : currentIndex,
    }),
    [currentIndex, getCurrentExperience],
  );

  let renderedOnboarding: React.ReactElement | React.ReactElement[] | null = null;
  const currentExperience = getCurrentExperience();
  if (currentExperience) {
    const { allAtOnce, steps } = currentExperience;
    const currentStepIdx = steps[currentIndex];
    if (isNotNil(currentStepIdx)) {
      renderedOnboarding = allAtOnce ? steps : currentStepIdx;
    }
  }

  return (
    <OnboardingContext.Provider value={value}>
      {children}
      <SnowplowContext.Provider value={snowplowValue}>
        <TargetPortal getTarget={() => document.body}>{renderedOnboarding}</TargetPortal>
      </SnowplowContext.Provider>
    </OnboardingContext.Provider>
  );
};
