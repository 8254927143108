import { useEffect } from 'react';
import { dangerous_markComponentAsRoutable } from './routingHelpers';

export const HardRedirect = dangerous_markComponentAsRoutable(({ to }: { to: string }) => {
  useEffect(() => {
    window.location.assign(new URL(to, window.location.origin));
  });

  return null;
});
