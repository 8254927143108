import styled from 'styled-components';
import { isNumber } from '~/utilities/type-guards';

export const AccountLogo = styled.img<{ maxHeight?: number }>`
  height: 100%;
  max-height: ${({ maxHeight }) => (isNumber(maxHeight) ? `${maxHeight}px` : '32px')};
  max-width: 100%;
  min-width: 32px;
  object-fit: contain;
`;
