import { BrowserRouter as Router } from 'react-router-dom';
import { AnonymousAppProviders } from './AnonymousAppProviders';
import { AnonymousAppRouter } from './AnonymousAppRouter';
import { Toaster } from 'sonner';

export const AnonymousApp = () => {
  return (
    <AnonymousAppProviders>
      <Router>
        <AnonymousAppRouter />
        <Toaster />
      </Router>
      <Toaster
        gap="24"
        visibleToasts={6}
      />
    </AnonymousAppProviders>
  );
};
