// File copied and modified from https://raw.githubusercontent.com/rmosolgo/graphql-ruby/master/javascript_client/src/subscriptions/ActionCableLink.ts
import { ApolloLink, Observable } from '@apollo/client';
import { print } from 'graphql/language/printer';

export class ActionCableLink extends ApolloLink {
  constructor({ cable, channelName, actionName, connectionParams }) {
    super();
    this.cable = cable;
    this.channelName = channelName || 'GraphqlChannel';
    this.actionName = actionName || 'execute';
    this.connectionParams = connectionParams || {};
  }

  // Interestingly, this link does _not_ call through to `next` because
  // instead, it sends the request to ActionCable.
  request(operation, _next) {
    return new Observable((observer) => {
      const channelId = Math.round(Date.now() + Math.random() * 100000).toString(16);
      const { actionName } = this;
      const subscription = this.cable.subscriptions.create(
        {
          channel: this.channelName,
          channelId,
          ...this.connectionParams,
        },
        {
          connected() {
            this.perform(actionName, {
              query: operation.query ? print(operation.query) : null,
              variables: operation.variables,
              // This is added for persisted operation support:
              operationId: operation.operationId,
              operationName: operation.operationName,
            });
          },
          received(payload) {
            if (payload.result.data || payload.result.errors) {
              observer.next(payload.result);
            }

            if (!payload.more) {
              observer.complete();
            }
          },
        },
      );

      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(subscription, { closed: false });
    });
  }
}
