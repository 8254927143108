import { Badge } from '@wistia/vhs';
import { environment } from '~/constantDefinitions';
import { useApplicationStatus } from '~/hooks/useApplicationStatus';

const colorMap = {
  development: 'red',
  staging: 'brandBlue',
  stagingBranchDeploy: 'brandBlue',
  canary: 'yellow',
  production: 'grey', // should never be needed, just for completeness
};

const iconMap = {
  development: 'embed',
  staging: 'private-user-sessions',
  stagingBranchDeploy: 'private-user-sessions',
  canary: 'hazard',
  production: 'hazard', // should never be needed, just for completeness
};

const labelMap = {
  development: 'DEVELOPMENT',
  staging: 'STAGING',
  stagingBranchDeploy: 'STAGING',
  canary: 'CANARY',
  production: 'PRODUCTION', // should never be needed, just for completeness
};

export const EnvironmentLabel = ({ showEnvironmentLabel = true }) => {
  const { isCanary } = useApplicationStatus();
  // presently, we can only determine that we're in a "canary" environment by the value of the "canary" key
  // returned from /status; the environment is still considered "production" so some measures are taken
  // to get it to display in the header
  const env = isCanary ? 'canary' : environment;
  const isNotProduction = environment !== 'production' || isCanary;
  const showEnv = showEnvironmentLabel && isNotProduction;

  return showEnv ? (
    <Badge
      colorScheme={colorMap[env]}
      icon={iconMap[env]}
      label={labelMap[env]}
      style={{ marginLeft: '8px' }}
      data-testid="environment-label"
    />
  ) : null;
};
