import { useState, useEffect } from 'react';
import { getAuthenticityToken } from '~/utilities/getAuthenticityToken';

type ResponseData = {
  sha: string;
  canary: boolean;
};

export const useApplicationStatus = (): { commitRef: string; isCanary: boolean } => {
  const [commitRef, setCommitRef] = useState<string>('');
  const [isCanary, setIsCanary] = useState<boolean>(false);

  const fetchStatus = async () => {
    try {
      const resp = await fetch('/status', {
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': getAuthenticityToken() ?? '',
        },
        credentials: 'include',
        method: 'get',
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: ResponseData = await resp.json();

      setCommitRef(data.sha);
      setIsCanary(data.canary);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    void fetchStatus();
  }, []);

  return { commitRef, isCanary };
};
